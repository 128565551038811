// This is the default html and body font-size for the base rem value.
// $rem-base: 16px;
// Allows the use of rem-calc() or lower-bound() in your settings
@import "./versions/2.0.6/bower_components/foundation/scss/foundation/functions";

// Mixins
@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

%nav-active-item-style {
  font-weight: normal;
  border-bottom: 2px solid #FFF;
  font-color: #FFF;
}

@mixin h1-styling {
  text-align: center;
  color: #331210;
}

@mixin button {
  border: none;
  padding: rem-calc(12) rem-calc(20);
  margin-top: $margin-small;
  margin-bottom: $margin-small;
  color: #fff;
  background: #331210;
  white-space: normal;
  float: left;
  font-size: $main-font-size;
  border-radius: 5px;
}

@mixin buttonLight {
  border: 1px solid #ddd;
  margin-top: $margin-small;
  margin-bottom: $margin-small;
  color: $main-color;
  background: #f8f8f8;
  white-space: normal;
}

@mixin buttonSmall {
  padding: rem-calc(10) rem-calc(15);
  font-size: rem-calc(13);
}

@mixin ghost-button-light {
  border: 1px solid $white;
  padding: rem-calc(15);
  color: $white !important;
  background: none;
  border-radius: rem-calc(10);
  font-family: $special-font;
  font-weight: 300;
}

@mixin ghost-button-dark {
  border: 1px solid $main-color;
  padding: rem-calc(15);
  color: $main-color !important;
  background: none;
  border-radius: rem-calc(10);
  font-family: $special-font;
  font-weight: 300;
}

@mixin page-layout {
  @if $boxed == false {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: initial;
  } @else {
    max-width: $frame-width;
    margin-left: auto;
    margin-right: auto;
  }
}

// VARIABLES THEME NEPTUNUS
$boxed: true; // True or false
$fullwidth-boxed: true;
$frame-width: 1280px; // Width of the frame
$body-background: #2f2e2e; // Background of the body (color and/or image[placement/repeat/sizing])
$frame-background: #fff; // Background of the frame (color and/or image[placement/repeat/sizing])
$primary-color: #630000; // Primary color
$secondary-color: #331210; // Secondary color
$success-color: #43AC6A;
$warning-color: #f04124;
$info-color: #a0d3e8;

// text
$white: #FFFFFF;
$black: #000000;

// Spaces
$margin-small: rem-calc(5);
$padding-small: rem-calc(5);
$margin-normal: rem-calc(15);
$padding-normal: rem-calc(15);
$margin-large: rem-calc(40);
$padding-large: rem-calc(40);
$section-spacing: rem-calc(20);

// Fonts
$main-font: 'Nunito', sans-serif; // Global font-family
$main-font-size: rem-calc(16); // Global font-size
$main-color: #2f2f2f; // Global font-color
$link-color: #096FB0; // Link color
$link-text-decoration: none;
$link-hover-color: darken($link-color, 20%);
$link-hover-text-decoration: none;
$special-font: 'Nunito', sans-serif; // Header font-family
$special-font-color: #630000; // Header text color
$date-color: #333;

// Intro
$intro-font: $main-font; // Intro font-family
$intro-font-size: rem-calc(17); // Intro font-size
$intro-color: #2f2f2f; // Intro font-color
$intro-font-weight: normal; // Intro font-weight
$intro-link-color: $primary-color; // Intro link-color
$border-color: #dddddd; // Color of the borders

// Heading
$h1-font-size: rem-calc(44); // H1 font-size
$h2-font-size: rem-calc(37); // H2 font-size
$h3-font-size: rem-calc(27); // H3 font-size
$h4-font-size: rem-calc(23); // H4 font-size
$h5-font-size: rem-calc(18); // H5 font-size
$h6-font-size: 1rem; // H6 font-size
$heading-font-weight: normal; // Heading font-weight
$heading-font-color: $special-font-color; // Heading font-color
$heading-font-family: $special-font; // Heading font-family
$heading-font-link-color: $primary-color; // Heading link color

// Article
$article-detail-background: lighten($border-color, 7%); // Background of the date, author and source on article pages
$article-detail-color: $main-color; // Font-color of the date, author and source on article pages

// Header
$header-topbar-font-size: rem-calc(14); // Header topbar font size
$header-topbar-color: $primary-color; // Header topbar font size
$header-nav-color: $special-font-color; // Header nav font-color
$header-nav-font-size: rem-calc(20); // Header nav font-size
$header-nav-font-weight: normal; // Header nav font-weight
$header-nav-font-family: $special-font; // Header nav font-weight
$header-nav-active-color: $primary-color; // Header nav font-color active item
$header-nav-line-height: rem-calc(60); // Header nav line-height
$header-nav-last-padding: 0 !important; // Header nav last item (no background color = 0, otherwise auto))
$header-nav-margin: 0; // Header nav margin
$header-nav-li-margin: 0; // Header nav li margin
$header-dropdown-bg: $primary-color; // Header nav dropdown background
$header-dropdown-link-color: #fff; // Header nav dropdown link color
$header-dropdown-link-bg: $primary-color; // Header nav dropdown link background
$topbar-dropdown-link-color-hover: #fff;
$header-dropdown-active-link-bg: lighten($primary-color, 10%);
$header-dropdown-active-link-color: #fff;
$header-dropdown-link-bg-hover: lighten($primary-color, 10%); // Header nav dropdown link background-hover
$header-dropdown-border: none; // Border-width, style and color
$header-dropdown-link-border-top: 1px solid $border-color;
$header-top-background: #FFF; // Header top background
$header-bottom-background: #FFF; // Header bottom background (nav)
$header-bottom-height: rem-calc(90); // Header bottom height = height of the logo
$header-top-border-bottom: 1px solid #fff;
$header-bottom-border-bottom: 1px solid $border-color; // Header border bottom
$header-fixed-bottom-border-bottom: 1px solid $border-color; // Header border bottom when fixed
$header-hamburger-text-visible: false;
$header-hamburger-text-color: #fff;
$header-hamburger-color: $primary-color;

// Header 3
$header3Fixed: false;
$header3ShowTopBar: false;
@mixin headerVariant3Logo {
  margin: $margin-normal 0;
}

@mixin headerVariant3Nav {
  float: right;
  margin: rem-calc(5) rem-calc(20) 0;
}

@mixin headerVariant3MobileNavIcon {
  top: 0;
  margin-top: rem-calc(30);
  a {
    z-index: 100;
  }
  a span:after {
    box-shadow: 0 0 0 2px $header-hamburger-color, 0 8px 0 2px $header-hamburger-color, 0 16px 0 2px $header-hamburger-color;
  }
}

@mixin headerVariant3MobileMenu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
}

@mixin headerVariant3Level1 {
  border-bottom: none;
  li a,
  li span {
    padding: $padding-normal !important;
    line-height: 1 !important;
    display: block;
    overflow: hidden;
    font-size: $header-nav-font-size;
    font-weight: $header-nav-font-weight;
    font-family: $header-nav-font-family;
  }
  li.active a {
    color: $primary-color;
  }
}

@mixin headerVariant3Level2 {
  li a,
  li.active a {
    padding: $padding-normal !important;
    line-height: 1;
  }
  li.active a {
    color: $primary-color;
  }
}

@mixin headerVariant3TopBar {
  font-size: $header-topbar-font-size;
  float: left;
  margin: 0;
  P {
    float: left;
    margin: 0;
    font-size: $header-topbar-font-size;
  }
  .fa {
    font-size: $header-topbar-font-size;
  }
}

@mixin headerVariant3TopBarLinks {
  border-left: 1px solid $border-color;
  margin-left: rem-calc(10);
  padding-left: rem-calc(10);
  font-size: $header-topbar-font-size;
}

@mixin headerVariant3DropdownBackLink {
  width: 100%;
  background: $primary-color;
  color: #fff;
  padding: $padding-normal !important;
}

@mixin headerVariant3DropdownLink {
  text-align: left;
  padding: $padding-normal 0 $padding-normal $padding-normal*2 !important;
  border: 0;
}

// Member Search
$memberSearchVariant1ShowMap: true;
$memberSearchVariant1ShowGrid: false;

// Slider
$slider-height: 25rem;
$slider-caption-background: rgba(255, 255, 255, 0.9); // Slider caption background (possible transparancy)
$slider-caption-color: $main-color; // Slider caption font-color
$slider-dot-color: $secondary-color;
$slider-dot-color-active: $primary-color;
$slider-padding: rem-calc(120) 0 rem-calc(120) 0; // Slider height

@mixin slider-prev {
  left: $padding-normal;
  margin-top: -20px;
}

@mixin slider-prev-before {
  font-family: 'FontAwesome';
  font-size: 40px;
  color: #fff;
  content: "\f0a8";
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

@mixin slider-next {
  right: $padding-normal;
  margin-top: -20px;
}

@mixin slider-next-before {
  font-family: 'FontAwesome';
  font-size: 40px;
  color: #fff;
  content: "\f0a9";
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

@mixin slider-caption {
  bottom: 0;
  background: $slider-caption-background;
  padding: $padding-normal;
  color: $slider-caption-color;
  font-family: $main-font;
  font-size: $main-font-size;
  @media #{$small-only} {
    width: 80%;
    font-size: 14px;
    margin: 0 auto;
  }
}

@mixin slider-caption-link {
  color: $link-color;
  font-family: $main-font;
  font-size: $main-font-size;
  text-decoration: $link-text-decoration;
}

@mixin slider-caption-title {
  color: $special-font-color;
  font-family: $special-font;
  font-size: $h3-font-size;
}

@mixin slider-slide {
  border-right: 3px solid #f8f8f8;
}

@mixin slider-slide-active {
}

@mixin slider-slide-inactive {
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

// Logo Bar
$logoBar-font-path: "/fonts/7/" !default;
$logoBar-font-family: "slick" !default;
$logoBar-loader-path: "./" !default;
$logoBar-arrow-color: red !default;
$logoBar-dot-color: black !default;
$logoBar-dot-color-active: $logoBar-dot-color !default;
$logoBar-prev-character: '\2190' !default;
$logoBar-next-character: '\2192' !default;
$logoBar-dot-character: '\2022' !default;
$logoBar-dot-size: 6px !default;
$logoBar-opacity-default: 0.75 !default;
$logoBar-opacity-on-hover: 1 !default;
$logoBar-opacity-not-active: 0.25 !default;

@mixin logoBar {
  position: relative;
  overflow: hidden;
  background: #f8f8f8;
  padding: 50px 0;
}

@mixin logoBar-arrows {
  position: absolute;
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px \9
; /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  &.slick-disabled:before {
    opacity: $logoBar-opacity-not-active;
  }
}

@mixin logoBar-prev {
  left: 0;
  &:before {
    font-family: 'FontAwesome';
    font-size: 40px;
    color: $primary-color;
    content: "\f0a8";
    opacity: 0.6;
    z-index: 100;
    position: relative;
    margin-left: 5px;
    &:hover {
      opacity: 1;
    }
  }
  &:after {
    background: rgba(255, 255, 255, 0.8);
    width: 50px;
    height: 100px;
    position: absolute;
    top: -50px;
    left: 0;
    content: " ";
  }
}

@mixin logoBar-next {
  right: 0;
  &:before {
    font-family: 'FontAwesome';
    font-size: 40px;
    color: $primary-color;
    content: "\f0a9";
    opacity: 0.6;
    z-index: 100;
    position: relative;
    margin-right: 5px;
    &:hover {
      opacity: 1;
    }
  }
  &:after {
    background: rgba(255, 255, 255, 0.8);
    width: 50px;
    height: 100px;
    position: absolute;
    top: -50px;
    right: 0;
    content: " ";
  }
}

@mixin logoBar-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 80px 0 0 0;
  float: left;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $logoBar-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $logoBar-dot-character;
        width: 20px;
        height: 20px;
        font-family: $logoBar-font-family;
        font-size: $logoBar-dot-size;
        line-height: 20px;
        text-align: center;
        color: $logoBar-dot-color;
        opacity: $logoBar-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $logoBar-dot-color-active;
      opacity: $logoBar-opacity-default;
    }
  }
}

@mixin logoBar-slide {
  float: left;
  height: auto;
  margin: 0 30px;
  text-align: center;
  -webkit-animation: logoBarCenterOutAnimation 0.5s;
  -moz-animation: logoBarCenterOutAnimation 0.5s;
  -o-animation: logoBarCenterOutAnimation 0.5s;
  animation: logoBarCenterOutAnimation 0.5s;
  a.title {
    display: block;
    overflow: hidden;
    text-align: center;
  }
}

@-webkit-keyframes logoBarCenterAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

@-moz-keyframes logoBarCenterAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

@-o-keyframes logoBarCenterAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

@keyframes logoBarCenterAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

@mixin logoBar-slide-img {
  width: 100%;
  height: auto;
}

@mixin logoBar-center-slide {
  transform: scale(1.4);
  -webkit-animation: logoBarCenterAnimation 0.5s;
  -moz-animation: logoBarCenterAnimation 0.5s;
  -o-animation: logoBarCenterAnimation 0.5s;
  animation: logoBarCenterAnimation 0.5s;
}

@-webkit-keyframes logoBarCenterOutAnimation {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@-moz-keyframes logoBarCenterOutAnimation {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@-o-keyframes logoBarCenterOutAnimation {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes logoBarCenterOutAnimation {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

// Meeting Subscription flow
@mixin meetingSubscriptionFlow {
  .meetingSubscription {

  }
  //padding:50px 0;
  //min-height:350px;
}

// Highlight bar
$highlight-background: $primary-color; // Highlight row (homepage) background color
$highlight-heading-color: #fff;
$highlight-color: #fff;
$highlight-border-color: #fff; // Color of the border in highlightbar
$highlight-link-color: #fff;
$highlight-icon-background: transparent;
$highlight-icon-color: $highlight-heading-color;
$highlight-icon-border: 2px solid $primary-color; // Border-width, style and color

// Parallax block
$parallaxBgColor: rgba($primary-color, 0.5); // Background (color) for parallax block

// Alert box
$alert-background: rgba(255, 255, 255, 0.2);
$alert-color: rgba(0, 0, 0, 0.6);
$alert-border: 5px dashed rgba(0, 0, 0, 0.4);

// Meeting
$meetingsVariant1ShowImage: false;
@mixin meetingProgramTable {

}

@mixin meetingSubscribeForm {
  background: #f8f8f8;
  border: 1px solid #dddddd;
}

// Tabs
$vertical-tabs: false;
@mixin tabStyling {
}

@mixin tabActiveStyling {
  background: $primary-color;
  color: #FFF;
}

@mixin tabContent {
  border: 1px solid $border-color;
  padding: $padding-normal;
}

// Change profile picture
@mixin changeProfilePicture {
  .currentProfilePictureWrapper {
    overflow: hidden;
    .currentProfilePicture {
      position: relative;
      float: left;
      .currentProfilePictureLink {
        position: absolute;
        right: 0;
        top: 0;
        a {
          font-size: 0;
          color: rgba(0, 0, 0, 0);
          &::before {
            padding: 5px;
            content: "\f00d";
            font-family: 'FontAwesome';
            font-size: 20px;
            color: $primary-color;
          }
        }
      }
    }
  }
  input[type=submit] {
    margin-top: 20px;
    @include button;
  }
  label.SN-Label,
  input[type=button],
  .SN-Form-PictureEdit-Profile-NewSpecifications,
  .crop_preview_wrapper,
  .crop_picture_wrapper {
    display: none;
  }
}

// Block settings
@mixin blockSettings {
  &.default {
  }
  &.light {
    background: #f8f8f8;
    border-radius: 15px;
    border: 1px solid #ccc;
    padding: 20px;
  }
  &.dark {
    border-radius: 15px;
    background: #555;
    padding: 20px;
    color: #fff;
    h1, h2, h3, h4, h5, h6 {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}

	// Comments
	$block-comments-link-to-email: auto;					// Link to emailaddress in comments (pointer-events)

	// Navigation
	$block-navigation-underline: 1px solid $border-color; 	// navigation underline (border bottom and top)

	// Person personal information
	$block-person-personal-information-bold-titles:bold;	// Titles left side (font-weight)
	$block-person-personal-information-underline:none;		// Titles left side (border-bottom)

	// Persons:
	$block-persons-link-to-detail: none;					// link to detail page (pointer-events) NOTICE: url's in hover will be non-clickable if set to auto
	$block-persons-show-overlay: block;						// hover (display)
	$block-persons-show-title: block;						// title without hover (display). NOTICE: overlay must be visible!

	// Poll
	$block-poll-table-or-div: table;					// table or div layout
	$block-poll-underline: 1px solid $border-color;		// show underline
	$block-poll-vote-button-position: left;				// vote right or left

	// Search
	$block-search-button-position: under; 				// right or under searchbar

// Welcome
$block-welcome-show-logout: block; // Welcome show logout (display)

// Workgroup
$workgroup-documentlist-even-odd-styling: false; // true gives rows odd/even styling

// Footer
$footer-top-background: $secondary-color; // Footer top background
$footer-top-heading-color: #FFF; // Footer top heading font-color
$footer-top-heading-font-weight: normal; // Footer top heading font-color
$footer-top-heading-font-family: $special-font; // Footer top heading font-color
$footer-top-color: #FFF; // Footer top font-color
$footer-top-link-color: #FFF; // Footer top link color
$footer-top-link-text-decoration: none;
$footer-top-button-border: 1px solid #FFF; // Footer top button border
$footer-bottom-background: #170605; // Footer bottom background
$footer-bottom-color: #FFF; // Footer bottom font-color
$footer-bottom-font-size: rem-calc(16);
$footer-bottom-font-weight: normal;
$footer-bottom-link-color: #FFF; // Footer bottom link color
$footer-bottom-link-font-size: $footer-bottom-font-size;
$footer-bottom-link-font-weight: $footer-bottom-font-weight;
$footer-bottom-link-text-decoration: none;

// Variables

// Layout
$nav-margin: 35px 0 8px 0;

// Variables from settings -- for a full list of variables check bower_components>foundation>scss>foundation>_settings.scss
// e. Typography
// - - - - - - - - - - - - - - - - - - - - - - - - -
// We use these to control header font sizes

// We use these to control header size reduction on small screens
$h1-font-reduction: rem-calc(10);
$h2-font-reduction: rem-calc(10);
$h3-font-reduction: rem-calc(5);
$h4-font-reduction: rem-calc(3);
$h5-font-reduction: 0;
$h6-font-reduction: 0;

$table-even-row-bg: #eee;
$table-head-bg: $primary-color;
$table-head-font-color: #fff;
$table-foot-bg: #555;
$table-foot-font-color: #fff;

// d. Media Query Ranges
// - - - - - - - - - - - - - - - - - - - - - - - - -
$small-range: (0em, 40em);
$medium-range: (40.063em, 64em);
$large-range: (64.063em, 92.875em);
$xlarge-range: (92.938em, 120em);
$xxlarge-range: (120.063em, 99999999em);

$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$small-up: $screen;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";
$medium-down: "#{$screen} and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";
$large-down: "#{$screen} and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";
$xlarge-down: "#{$screen} and (max-width:#{lower-bound($xlarge-range)})";

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";
$xxlarge-down: "#{$screen} and (max-width:#{lower-bound($xxlarge-range)})";

// Legacy
$small: $medium-up;
$medium: $medium-up;
$large: $large-up;

// b. Grid
// - - - - - - - - - - - - - - - - - - - - - - - - -
$column-gutter: rem-calc(50);
$body-font-family: $main-font;
$header-font-weight: $heading-font-weight;
$header-font-color: $heading-font-color;
$header-font-family: $heading-font-family;
$paragraph-font-size: $main-font-size;

$anchor-text-decoration: $link-text-decoration;
// 30. Tables
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-table-classes: $include-html-classes;

// These control the background color for the table and even rows
// $table-bg: $white;
// $table-even-row-bg: $snow;

// These control the table cell border style
// $table-border-style: solid;
// $table-border-size: 1px;
// $table-border-color: $gainsboro;

// These control the table head styles
// $table-head-bg: $white-smoke;
// $table-head-font-size: rem-calc(14);
// $table-head-font-color: $jet;
// $table-head-font-weight: $font-weight-bold;
// $table-head-padding: rem-calc(8 10 10);

// These control the table foot styles
// $table-foot-bg: $table-head-bg;
// $table-foot-font-size: $table-head-font-size;
// $table-foot-font-color: $table-head-font-color;
// $table-foot-font-weight: $table-head-font-weight;
// $table-foot-padding: $table-head-padding;

// These control the caption
// table-caption-bg: transparent;
// $table-caption-font-color: $table-head-font-color;
// $table-caption-font-size: rem-calc(16);
// $table-caption-font-weight: bold;

// These control the row padding and font styles
// $table-row-padding: rem-calc(9 10);
$table-row-font-size: $main-font-size;
// $table-row-font-color: $jet;
// $table-line-height: rem-calc(18);

// 34. Top Bar
// - - - - - - - - - - - - - - - - - - - - - - - - -

// Background color for the top bar
$topbar-bg-color: $header-bottom-background;
$topbar-bg: $topbar-bg-color;

// Height and margin
$topbar-height: $header-bottom-height;

// Style the top bar dropdown elements
$topbar-dropdown-bg: $header-dropdown-bg;
$topbar-dropdown-link-color: $header-dropdown-link-color;
$topbar-dropdown-link-bg: $header-dropdown-link-bg;
$topbar-dropdown-link-bg-hover: $header-dropdown-link-bg-hover;
$topbar-link-dropdown-padding: rem-calc(10);

// Set the link colors and styles for top-level nav
$topbar-link-color: $header-nav-color;
$topbar-link-color-hover: $header-nav-active-color;
$topbar-link-color-active: $header-nav-active-color;
$topbar-link-color-active-hover: $header-nav-active-color;
$topbar-link-font-size: $main-font-size;
$topbar-link-active-weight: normal;
$topbar-link-hover-lightness: -10%; // Darken by 10%
$topbar-link-bg: transparent;
$topbar-link-bg-color-hover: transparent;
$topbar-link-bg-hover: transparent;
$topbar-link-bg-active: transparent;
$topbar-link-bg-active-hover: transparent;
$topbar-link-padding: rem-calc(15);
$topbar-dropdown-label-bg: $white;
$topbar-menu-link-color: $main-color;
$topbar-menu-icon-color: $main-color;
$topbar-arrows: false; //Set false to remove the triangle icon from the menu item
$opposite-direction: right;
$topbar-menu-icon-position: $opposite-direction;
$topbar-media-query: $large-up;
